<template>
  <div>
    <header class="op-header">
      <div>
        <el-select v-model="params.site_id" class="el-select">
          <el-option
            v-for="item in siteList"
            :key="item.site_id"
            :value="item.site_id"
            :label="item.proxy_name"
          ></el-option>
        </el-select>
        <el-input
          v-model="params.seller_id"
          class="item"
          placeholder="请输入卖家ID"
          @keyup.native.enter="search"
        ></el-input>
        <el-button style="margin-left: 15px;" @click="reset">重置</el-button>
        <el-button type="primary" class="item" @click="search">搜索</el-button>
      </div>
      <div>
        <el-button type="primary" @click="visible = true"
          >添加封禁卖家</el-button
        >
      </div>
    </header>
    <DragTable :header="header" :list-query="list">
      <template #state="{row}">{{state[row.state]}}</template>
      <template #seller_link="{row}"><a :href="row.seller_link" target="_blank">{{row.seller_link}}</a></template>
      <template #control="{ row }"
        ><el-button type="text" @click="del(row)">删除</el-button></template
      >
    </DragTable>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="params.page"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="params.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="params.total"
    >
    </el-pagination>
    <go-dialog class="op-dialog" v-model="visible" width="500px">
      <div class="item">
        <span>选择站点</span>
        <div>
          <el-radio
            v-model="data.site_id"
            v-for="item in siteList"
            :key="item.site_id"
            :label="item.site_id"
            >{{item.proxy_name}}</el-radio
          >
        </div>
      </div>
      <div class="item">
        <span>选择卖家</span>
        <el-input v-model="data.seller_id" placeholder="输入卖家ID" />
      </div>
      <div class="item">
        <span>源站链接</span>
        <el-input v-model="data.seller_link" placeholder="输入卖家源站链接（非必填）" />
      </div>
      <div class="item">
        <span>封禁原因</span>
        <el-select v-model="data.ban_reason_id" placeholder="选择封禁原因">
          <el-option v-for="item in readonList" :key="item.id" :value="item.id" :label="item.title"></el-option>
        </el-select>
      </div>
      <div class="item" v-if="data.ban_reason_id == 5">
        <span>原因补充</span>
        <el-input v-model="data.ban_other_reason" />
      </div>
      <footer class="footer">
        <el-button type="warning" @click="confirm">立即封禁</el-button>
        <el-button type="warning" @click="cancel">取消</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  setup(prop, { root }) {
    const state = {
      2: '封禁'
    }
    const params = reactive({
      site_id: 19,
      seller_id: "",
      page: 1,
      limit: 10,
      total: 0,
    });
    const data = reactive({
      site_id: "",
      seller_id: "",
      seller_link: "",
      ban_reason_id: '',
      ban_other_reason: "",
    });
    const visible = ref(false);
    const siteList = ref([]);
    const readonList = ref([])
    const header = [
      { name: "卖家站点", value: "site_name" },
      { name: "卖家ID", value: "seller_id" },
      { name: "卖家链接", slot: "seller_link" },
      { name: "状态", slot: "state" },
      { name: "封禁原因", value: "final_reason" },
      { name: "操作人", value: "operator" },
      { name: "操作时间", value: "operation_at" },
      { name: "编辑", slot: "control" },
    ];
    const list = reactive({ data: [] });
    watch(visible, (val) => {
      if (val) return;
      Object.keys(data).forEach((key) => {
        data[key] = "";
      });
    });
    const getList = () => {
      if(params.seller_id) {
        params.seller_id = params.seller_id.trim()
      }
      root.$axios
        .get("/orderSafe/platformBan/sellerBan/list", {
          params,
        })
        .then((res) => {
          list.data = res.data.list;
          params.total = res.data.total;
        })
        
    };
    getList()
    const search = () => {
      params.page = 1
      getList()
    }
    const getSite = () => {
      root.$axios
        .get("/orderSafe/platformBan/site/list")
        .then((res) => {
          siteList.value = res.data;
        })
        
    };
    getSite();
    const getReason = () => {
      root.$axios.get(`/orderSafe/platformBan/reason/list`, {
        params: { type: 1 }
      })
      .then(res=>{
        readonList.value = res.data.list
      })
      
    }
    getReason()
    const reset = () => {
      Object.assign(params, {
        site_id: 19,
        seller_id: "",
        page: 1,
        limit: 10,
      });
      list.data = []
      getList()
    };
    const handleSizeChange = (e) => {
      params.limit = e;
      getList();
    };
    const handleCurrentChange = (e) => {
      params.page = e;
      getList();
    };
    const del = async (data) => {
      const confirm = await root.$goDialog('是否删除？')
      if(!confirm) return
      root.$axios.post('/orderSafe/platformBan/sellerBan/delete', {
        id: data.id
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
      
    };
    const confirm = () => {
      if (!data.site_id) {
        root.$message.warning("请选择站点");
        return;
      }
      if (!data.seller_id || !data.seller_id.trim()) {
        root.$message.warning("请输入卖家ID");
        return;
      }
      if(!data.ban_reason_id) {
        root.$message.warning("请选择封禁原因");
        return;
      }
      if (data.ban_reason_id == 5 && (!data.ban_other_reason || !data.ban_other_reason.trim())) {
        root.$message.warning("请补充封禁原因");
        return;
      }
      if(data.seller_link && !data.seller_link.includes('http://') && !data.seller_link.includes('https://')) {
        root.$message.warning('请输入正确地址格式')
        return
      }
      root.$axios.post(`/orderSafe/platformBan/sellerBan/add`, data)
      .then(res=>{
        root.$message.success(res.msg)
        if(params.site_id || params.seller_id) {
          getList()
        }
        cancel();
      })
      
    };
    const cancel = () => {
      for(let key in data) {
        data[key] = ''
      }
      visible.value = false;
    };
    return {
      state,
      params,
      siteList,
      header,
      visible,
      data,
      list,
      readonList,
      search,
      reset,
      getList,
      del,
      cancel,
      confirm,
      handleSizeChange,
      handleCurrentChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.op-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  & > div {
    display: flex;
    align-items: center;
    .el-select {
      width: 290px;
    }
    .item {
      margin-left: 15px;
    }
  }
}
.op-dialog {
  .item {
    display: flex;
    margin-bottom: 20px;
    & > span {
      width: 100px;
    }
    & > div {
      width: 100%;
    }
    & > div > label {
      margin-right: 20px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
}
.el-button{
  border-radius: 5px;
}
</style>